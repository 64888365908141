import { useEffect, useState } from "react";
import arrow from "../images/arrow.svg";
import headerlogo from "../images/header-logo.svg";
import { useNavigate } from "react-router-dom";
import { getItemList } from "../utils";

function Home() {
  const [list, setList] = useState([]);
  const [activePopup, setActivePopup] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await getItemList();
        setList(res.data);
        console.log(res.data);
      } catch (error) {
        console.error("Error fetching item list:", error);
      }
    };
    
    fetchItems();
  }, []);

  const handlePopUpClick = (popupId) => {
    console.log(popupId);
    setActivePopup((prevPopupId) => (prevPopupId === popupId ? null : popupId));
  };
 
  
    
    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_BASE_URL}get-links`)
    //           .then(res => {
    //                 if (res.data.status === 'success') {
    //                     setLinks(res.data.data)
    //                 }
    //           })
    // }, []);

    const openPdf = () => {
      const pdfUrl = 'https://see-to-hear-quick-link-admin.dreamit.technology/uploads/packaging.pdf';
      window.open(pdfUrl, "_blank");
    }

    
  return (
    <div className="App" >
      <section className="main-sec">
        <header className="header">
          <img src={headerlogo} alt="logo" />
          <hr />
          <a onClick={() => navigate("/video")} className="h1">
            Video
          </a>
          <a onClick={openPdf} className="h1">
           Pdf
          </a>
        </header>
        <div className="main-block">
          {list.map((item) => (
            <div className={`video-block ${activePopup === item.id ? "active" : ""}`} key={item.id}>
              <div className="img-block" onClick={() => handlePopUpClick(item.id)}>
                {/* <a href="#!">
                  <img src={item.image} alt={item.name} />
                </a> */}
                <h2>{item.name}</h2>
              </div>
              <div className={`popup-block`}>
                {item.links.map((link) => (
                  <a href={link.url} target="_blank" rel="noopener noreferrer" key={link.url}>
                    {link.name}
                  </a>
                ))}
              </div>
              <div  class="arrow-block " onClick={() => handlePopUpClick(item.id)}>
                  <img src={arrow} alt="arrow" />
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Home;
