import axios from "axios"

export const getItemList = async () => {
    const options = {
      method: "GET",
      // url: "https://see-to-hear-quick-link-admin.tech-and-beyond.com/api/get-links",
      url: "https://see-to-hear-quick-link-admin.dreamit.technology/api/get-links",
      headers: {
        "content-type": "application/json",
      }
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };


  export const getGuideList = async () => {
    const options = {
      method: "GET",
      // url: "https://see-to-hear-quick-link-admin.tech-and-beyond.com/api/get-guides",
      url: "https://see-to-hear-quick-link-admin.dreamit.technology/api/get-guides",
      headers: {
        "content-type": "application/json",
      }
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };