import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from './pages/home.js';
import Video from './pages/video.js';
import './App.css';

function App() {
  return (
    <>
        <BrowserRouter>
            <Routes>
                <Route
                    exact
                    path="/"
                    element={<Home />}
                />
                <Route
                    exact
                    path="/video"
                    element={<Video />}
                />
            </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
